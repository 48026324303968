import { render, staticRenderFns } from "./Testimonial.vue?vue&type=template&id=3ad577a1&scoped=true"
import script from "./Testimonial.vue?vue&type=script&lang=js"
export * from "./Testimonial.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ad577a1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Richtext: require('/vercel/path0/components/elements/Richtext.vue').default,Picture: require('/vercel/path0/components/elements/Picture.vue').default,AnimationCustomFadeIn: require('/vercel/path0/components/elements/Animations/CustomFadeIn.vue').default,Section: require('/vercel/path0/components/elements/Section.vue').default})
